import { InputBase } from "../InputBase";
import { FilledInputClassKey, FilledInputProps } from "./types";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { tmc } from "../utils/tmc";
import overrideStyles from "../utils/overrideStyles";
import { ClassNameMap } from "../types/StandardProps";

const componentStyles: ClassNameMap<FilledInputClassKey> = {
  /* Base styles applied to `div` wrapping input*/
  root: "relative bg-[rgba(0,0,0,0.09)] rounded-tl-[4px] rounded-tr-[4px] transition-colors duration-[200ms] ease-[cubic-bezier(0.0, 0, 0.2, 1)] delay-[0ms] hover:bg-[rgba(0,0,0,0.13)]",

  /* Styles applied to `div` when color is secondary*/
  "colorSecondary:underline": "after:border-b-[#f50057] hover:border-none",

  /* Styles applied to the `div` element if the component is focused. */
  focused: "bg-[rgba(0,0,0,0.09)]",

  /* Styles applied to the `div` element if `error={true}`. */
  error: "",

  /* Styles applied to the `div` element if `disabled={true}`. */
  disabled: "bg-[rgba(0,0,0,0.12)]",

  /* Styles applied to the `div` element if `disableUnderline={false}`. */
  underline:
    "before:absolute before:left-0 before:right-0 before:bottom-0 before:transition-[border-bottom-color] before:duration-[200ms] before:ease-[cubic-bezier(0.4, 0, 0.2, 1)] before:delay-[0ms] before:border-b-[1px] before:border-b-[rgba(0,0,0,0.42)] before:pointer-events-none hover:before:border-b-[1px] hover:before:border-b-[rgba(0,0,0,0.87)] after:left-0 after:right-0 after:bottom-0 after:content-[''] after:absolute after:transform after:scale-x-0 after:transition-transform after:duration-[200ms] after:ease-[cubic-bezier(0.0, 0, 0.2, 1)] after:delay-[0ms] after:border-b-2 after:border-b-[#3f51b5]",

  /* Styles applied to the `div` element if `disableUndlerline={false}` and the component is focused. */
  "underline:focused": "after:transform after:scale-x-100",

  /* Styles applied to the `div` element if `disableUnderline={false}` and `error={true}`. */
  "underline:error":
    "after:transform after:scale-x-100 after:border-b-[#f44336]  hover:border-none",

  /* Styles applied to the `div` element if `disableUnderline={false}` and `disabled={true}`. */
  "underline:disabled": "border-b-dotted",

  /*Styles applied to the `div` element iif `startAdornment` is provided. */
  adornedStart: "pl-[12px]",

  /* Styles applied to the `div` element if `endAdornment` is provided. */
  adornedEnd: "pr-[12px]",

  /* Styles applied to the `div` element if `fullWidth={true}`. */
  fullWidth: "",

  /* Styles applied to the `div` element if `margin="dense"`. */
  marginDense: "",

  /* Styles applied to the `div` element if `multiline={true}`. */
  multiline: "pt-[27px] pr-[12px] pb-[10px] pl-[12px]",

  /* Styles applied to the `div` element if `multiline={true}` and `margin="dense". */
  "multiline:marginDense": "pt-[23px] pb-[6px]",

  /* Base Styles applied to the `input` element. */
  input: "pt-[27px] pr-[12px] pb-[10px] pl-[12px]",

  /* Styles applied to the `input` element if `margin="dense"`. */
  "input:marginDense": "pt-[23px] pb-[6px]",

  /* Styles applied to the `input` element if `hiddenLabel={true}`. */
  "input:hiddenLabel": "pt-[18px] pb-[19px]",

  /* Styles applied to the `input` element if `hiddenLabel={true}` and `margin="dense"`. */
  "input:hiddenLabel:marginDense": "pt-[10px] pb-[11px]",

  /* Styles applied to the `input` element if `multiline={true}`. */
  "input:multiline": "p-[0px]",

  /* Styles applied to the `input` element if `startAdornment` is provided. */
  "input:adornedStart": "pl-[0px]",

  /* Styles applied to the `input` element if `endAdornment` is provided. */
  "input:adornedEnd": "pr-[0px]"
};

const FilledInputRederFunction: ForwardRefRenderFunction<
  HTMLInputElement,
  FilledInputProps
> = (props, ref) => {
  const {
    disableUnderline,
    classes,
    fullWidth = false,
    inputComponent = "input",
    multiline = false,
    type = "text",
    ...other
  } = props;

  /* overrideStyles iterates through each key in componentStyles and overrides its styles with the corresponding styles from classes for matching keys. */
  const overriddenStyles: any = overrideStyles(
    "Input",
    componentStyles,
    classes
  );

  return (
    <InputBase
      classes={{
        root: tmc(overriddenStyles.root, {
          [overriddenStyles.underline]: !disableUnderline
        }),
        colorSecondary: overriddenStyles["colorSecondary:underline"],
        marginDense: overriddenStyles.marginDense,
        multiline: overriddenStyles.multiline,
        "multiline:marginDense": overriddenStyles["multiline:marginDense"],
        adornedStart: overriddenStyles.adornedStart,
        adornedEnd: overriddenStyles.adornedEnd,
        fullWidth: overriddenStyles.fullWidth,
        focused: tmc(overriddenStyles.focused, {
          [overriddenStyles["underline:focused"]]: !disableUnderline
        }),
        error: tmc(overriddenStyles.error, {
          [overriddenStyles["underline:error"]]: !disableUnderline
        }),
        disabled: tmc(overriddenStyles.disabled, {
          [overriddenStyles["underline:disabled"]]: !disableUnderline
        }),
        input: overriddenStyles.input,
        "input:marginDense": overriddenStyles["input:marginDense"],
        "input:multiline": overriddenStyles["input:multiline"],
        "input:hiddenLabel": overriddenStyles["input:hiddenLabel"],
        "input:hiddenLabel:marginDense":
          overriddenStyles["input:hiddenLabel:marginDense"],
        "input:adornedStart": overriddenStyles["input:adornedStart"],
        "input:adornedEnd": overriddenStyles["input:adornedEnd"]
      }}
      fullWidth={fullWidth}
      inputComponent={inputComponent}
      multiline={multiline}
      ref={ref as any}
      type={type}
      {...other}
    />
  );
};

export const FilledInput = forwardRef(FilledInputRederFunction);

(FilledInput as any).muiName = "Input";

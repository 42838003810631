import React, { useRef } from "react";
import {
  cloneElement,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect
} from "react";
import { Transition } from "react-transition-group";
import { reflow } from "../utils/transitions";
import useForkRef from "../utils/useForkRef";
import { GrowProps } from "./types";
import { addDebugInfo } from "../utils/overrideStyles";

function getScale(value: number): string {
  return `scale(${value}, ${value ** 2})`;
}

const styles = {
  entering: {
    opacity: 1,
    transform: getScale(1)
  },
  entered: {
    opacity: 1,
    transform: "none"
  }
};

const GrowRenderFunction: ForwardRefRenderFunction<HTMLElement, GrowProps> = (
  props,
  ref
) => {
  const {
    children,
    disableStrictModeCompat = false,
    in: inProp,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    style,
    timeout = "auto",
    TransitionComponent = Transition,
    ...other
  } = props;

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const enableStrictModeCompat = !disableStrictModeCompat;
  const nodeRef = useRef<HTMLElement>(null);
  const foreignRef = useForkRef((children as any).ref, ref);
  const handleRef = useForkRef(
    enableStrictModeCompat ? nodeRef : undefined,
    foreignRef
  );

  const normalizedTransitionCallback =
    (callback?: (node: HTMLElement, isAppearing?: boolean) => void) =>
    (nodeOrAppearing: HTMLElement | boolean, maybeAppearing?: boolean) => {
      if (callback) {
        const [node, isAppearing] = enableStrictModeCompat
          ? [nodeRef.current, nodeOrAppearing as boolean]
          : [nodeOrAppearing as HTMLElement, maybeAppearing];

        if (node === undefined || node === null) {
          return;
        }

        if (isAppearing === undefined) {
          callback(node);
        } else {
          callback(node, isAppearing);
        }
      }
    };

  const handleEntering = normalizedTransitionCallback(onEntering);

  const handleEnter = normalizedTransitionCallback(
    (node: HTMLElement, isAppearing: boolean) => {
      reflow(node);

      const duration = typeof timeout === "number" ? timeout : 300;

      node.classList.add("transition-all", "ease-in-out");
      node.style.transitionDuration = `${duration}ms`;
      node.style.transitionProperty = "opacity, transform";

      if (onEnter) {
        onEnter(node, isAppearing);
      }
    }
  );

  const handleEntered = normalizedTransitionCallback(onEntered);
  const handleExiting = normalizedTransitionCallback(onExiting);

  const handleExit = normalizedTransitionCallback((node: HTMLElement) => {
    const duration = typeof timeout === "number" ? timeout : 300;

    node.classList.add("transition-all", "ease-in-out");
    node.style.transitionDuration = `${duration}ms`;
    node.style.transitionProperty = "opacity, transform";

    node.style.opacity = "0";
    node.style.transform = getScale(0.75);

    if (onExit) {
      onExit(node);
    }
  });

  const handleExited = normalizedTransitionCallback(onExited);

  const addEndListener = (
    nodeOrNext: HTMLElement | (() => void),
    maybeNext?: () => void
  ) => {
    const next = enableStrictModeCompat
      ? (nodeOrNext as () => void)
      : maybeNext!;

    if (timeout === "auto" && typeof window !== "undefined") {
      timer.current = setTimeout(next, 300);
    } else if (timeout === "auto") {
      next();
    }
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <TransitionComponent
      appear
      in={inProp}
      nodeRef={enableStrictModeCompat ? nodeRef : undefined}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExited}
      onExiting={handleExiting}
      addEndListener={addEndListener}
      timeout={timeout === "auto" ? 300 : timeout}
      {...other}
    >
      {(state: string, childProps: { [key: string]: any }) => {
        return cloneElement(children ?? <></>, {
          style: {
            opacity: 0,
            transform: getScale(0.75),
            visibility: state === "exited" && !inProp ? "hidden" : undefined,
            ...styles[state as keyof typeof styles],
            ...style,
            ...children?.props.style
          },
          className: `transition-all ease-in-out 
          ${addDebugInfo(
            "Grow$children",
            "className",
            children?.props.className ?? ""
          )}`,
          ref: handleRef,
          ...childProps
        });
      }}
    </TransitionComponent>
  );
};

export const Grow = forwardRef(GrowRenderFunction);
